import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Container from "./components/container/container";
import Home from "./components/home/home";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Contact from "./components/contact/contact";
import WhatWeDo from "./components/whatwedo/whatwedo";
import Prayers from "./components/prayers/prayers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const routes = [
  {
    path: "",
    element: <Container />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/what-we-do",
        element: <WhatWeDo />,
      },
      {
        path: "/prayers",
        element: <Prayers />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

root.render(<RouterProvider router={router} />);

reportWebVitals();
