import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/header";

const Container = function Container() {
    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: "column"}}>
                    <Header />
                    <Outlet />
            </div>
        </>
    );
};

export default Container;
