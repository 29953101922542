import React from "react";
import styles from "./whatwedo.module.scss";

export default function WhatWeDo() {
  return (
    <div className={styles.container}>
      <div>
        <h1>Tevékenységünk</h1>
        <p>
          Terjesztjük az igét az informatika
          <br />
          csodás világáról, a mit sem sejtő laikusok számára.
        </p>
      </div>
    </div>
  );
}
