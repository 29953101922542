import React from "react";
import { Link } from "react-router-dom";
import styles from "./header.module.scss";
import Temple from "../../assets/temple.svg";

export default function Header() {
    return (
        <div className={styles.container}>
            <img src={Temple} alt="" />
            <Link to="/" className={styles.link}>TechTemple</Link>
            <Link to="/contact" className={styles.link}>Kapcsolat</Link>
            <Link to="/what-we-do" className={styles.link}>Tevékenységünk</Link>
            <Link to="/prayers" className={styles.link}>Imák</Link>
        </div>
    );
};
