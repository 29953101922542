import React from "react";
import styles from "./contact.module.scss";

export default function Contact() {
  return (
    <div className={styles.container}>
      <div>
        <h1>Kapcsolat</h1>
        <p>Telefon: +36 90 603 220 </p>
      </div>
    </div>
  );
}
