import React from "react";
import styles from "./home.module.scss";

export default function Home() {
    return (
        <div className={styles.container}>
            <div>   
                <h1>Üdvözöljük a TechTemple honlapján!</h1>
                <p>
                    Ez az oldal a TechTemple nevű egyházról szól.
                    <br />
                    Célunk, hogy az informatika csodás világát
                    <br />
                    a hívőknek és a nem hívőknek is megismertessük.
                </p>
            </div>
        </div>
    );
};
