import React from "react";
import styles from "./prayers.module.scss";

export default function Prayers() {
  return (
    <div className={styles.container}>
      <div>
        <h1>Imák</h1>
        <br />
        <h2>Ima a hibamentes kódról:</h2>
        <p>Szent JavaScript, segíts, hogy kódjaim hibamentesek legyenek, és ne okozzanak fejfájást a felhasználóknak.</p>
        <br />
        <h2>Ima a bugok elkerüléséért:</h2>
        <p>Szent Debugger, kérlek, vezess engem a helyes úton, hogy elkerüljem a bugokat és a hibákat.</p>
        <br />
        <h2>Ima a hatékony algoritmusokért:</h2>
        <p>Szent Big O, adj nekem bölcsességet, hogy hatékony algoritmusokat tervezhessek és implementálhassak.</p>
        <br />
        <h2>Ima a gyors és skálázható rendszerekért:</h2>
        <p>Szent Cloud, áldj meg engem a gyors és skálázható rendszerek megteremtésének képességével.</p>
        <br />
        <h2>Ima a biztonságos adatvédelemért:</h2>
        <p>Szent SSL, óvd meg az adatokat a rosszindulatú támadásoktól és a szivárgásoktól.</p>
      </div>
    </div>
  );
}
